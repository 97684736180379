import request from '@/utils/request.js';

let game_id = location.pathname.split("/")[1]
function getUrl(){
    let url
    if(localStorage.getItem(`${game_id}_scriptUrl`)){
        url = localStorage.getItem(`${game_id}_scriptUrl`).split("phonocatstudio.com")[1]
    }else{  
        url = `/api/${game_id}`
    }
    // console.log(`scriptUrl_nextPage`, url)
    return url
}


export const getNextPage = () =>
    request("post", getUrl())
        .then(function (res) {
            // console.log('apiGetRoutes:',res.data);
            return res;
        })
        .catch(error => { return error });

export const sendOption = data =>
    request("post", getUrl(), data)
        .then(function (res) {
            // console.log('apiGetRoutes:',res.data);
            return res;
        })
        .catch(error => { return error });