<template>
<div class="bg">
  <div class="functionMenu-menu" :style="{ 'background-image': `url(${images.groups_background_image})`}">
     <div class="menu_closeBtn" :style="{ 'background-image': `url(${images.close_button_image})`}" @click="handleClick('none')">
      </div>
      <div class="menu_contents d-flex flex-wrap justify-content-between">
        <div class="menu_content" v-for="m  in menu" :key="m.name"  @click="handleClick(m)" :style="{ 'background-image': `url(${m.icon_image})`}">
        </div>
        <div class="menu_content"   @click="handleConversation()" :style="{ 'background-image': `url(${images.dialog_log_button_image})`}">
        </div>
      </div>
   </div>
</div>
   
</template>

<script>
// import MenuItemModal from './MenuItem_Modal.vue'

    export default {
      components:{
        // MenuItemModal
      },
      props:{
        menu:{
          type:Array
        },
        images:{
          type:Object
        }
      },
     created() {
    let vw = window.innerWidth;
    const setFillHeight = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    };

    window.addEventListener("resize", () => {
      if (vw === window.innerWidth) {
        return;
      }

      vw = window.innerWidth;
      setFillHeight();
    });
  },
      data(){
        return{
          imenuList:['menuItem','menuItem','menuItem','menuItem','menuItem','coversationList']
        }
      },

        methods:{
          handleClick(val){
            if(val === 'none'){
              this.$emit('handleClick', val); 
            }
            else{
               this.$emit('handleClick', val); 
            }
          },

          handleConversation(){
            this.$emit('handleConversation'); 
          }
        }
    }

</script>

<style lang="scss" scoped>
    .bg{
      width: 100vw;
      height: 100vh;
     height: calc(var(--vh, 1vh) * 100);
      background: rgba(0,0,0,.2);
      z-index:15;
      position: absolute;
      top:0;
      backdrop-filter: blur(5px) ;
      padding: 5.7vh 5.06vw 0 5.06vw;
      
    }
   .functionMenu-menu{
      width: 90vw;
      height: 82.1vh;
      height: calc(var(--vh, 1vh) * 82.1);
      // background: url('../../assets/background/bg_functionMenuModal.png');
       background-size: cover;
       position: relative;
       padding: 14.37% 6.67% 0 6.67%;
    }

    .menu_closeBtn{
      // background: url('../../assets/icons/btn_clase.png');
      width: 18.4vw;
      height:18.4vw ;
      background-size: contain;
      position: absolute;
      top:-4vh;
      left:-8vw;
       cursor: pointer;
    }

    .menu_content{
      // background: url('../../assets/icons/itemList_icon.png');
      width: 37vw;
      height: 37vw;
      background-size: cover;
      margin-bottom:3.73vw;
      &:nth-last-child(1){
        //  background: url('../../assets/icons/itemList_conversation_icon.png');
         width: 37vw;
         height: 37vw;
         background-size: cover;
         margin-bottom:3.73vw;
      }
    }
</style>