<template>
  <div v-if="link" class="bg">
    <div class="functionMenu-menu" :style="{ 'background-image': `url(${images.embed_background_image})` }">
      <div class="menu_closeBtn" :style="{ 'background-image': `url(${images.close_button_image})` }"
        @click="handleClick('none'); addContent()">
      </div>
      <!-- <iframe width="96%" height="54%" :src="link | ytUrltoEmbed" title="YouTube video player" frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen></iframe> -->
      <div id="player"></div>
    </div>

  </div>
</template>
<script>
export default {
  props: {
    link: {
      type: String
    },
    images: {
      type: Object
    }
  },
  mounted() {
    const url = this.link.split("watch?v=")[1]
    const tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    const firstScript = document.getElementsByTagName('script')[0];
    firstScript.parentNode.insertBefore(tag, firstScript);
    let player;
    let vm = this
    window.onYouTubeIframeAPIReady = function () {
      player = new YT.Player('player', {
        height: '54%', 
        width: '100%', 
        videoId: `${url}`,
        playerVars: {
          playsinline:0
    },
        events: {
          'onReady': onPlayerReady,
          'onStateChange': function (event) {
            if (event.data == YT.PlayerState.ENDED) {
              vm.addContent()
            }
          }
        }
      });
    }
    function onPlayerReady(e) {
      // 為確保瀏覽器上可以自動播放，要把影片調成靜音
      e.target.mute().playVideo();
    }
  },
  filters: {
    ytUrltoEmbed(url) {
      let newUrl = url.replace('watch?v=', 'embed/')
      // console.log(`newUrl:`, newUrl)
      return newUrl;
    },
  },
  methods: {
    addContent() {
      this.$emit("toNextPage")
    },
    handleClick(val) {
      if (val === 'none') {
        this.$emit('handleClick', val);
      }
      else {
        this.$emit('handleClick', val);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.bg {
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  background: rgba(0, 0, 0, 0.2);
  z-index: 15;
  position: absolute;
  top: 0;
  backdrop-filter: blur(5px);
  padding: 5.7vh 6.06vw 0 6.06vw;
}

.functionMenu-menu {
  width: 88vw;
  height: 82.1vh;
  height: calc(var(--vh, 1vh) * 82.1);
  // background: url('../../assets/background/bg_functionMenuModal.png');
  background-size: cover;
  position: relative;
  padding: 14.37% 0 0 0;
}

.menu_closeBtn {
  // background: url('../../assets/icons/btn_clase.png');
  width: 18.4vw;
  height: 18.4vw;
  background-size: contain;
  position: absolute;
  top: -4vh;
  left: -8vw;
  cursor: pointer;
}
</style>