<template>
  <div class="bg">
    <div class="menuItem" :style="{ 'background-image': `url(${items.content_background_image})`}">
      <div
        class="menuItem_contents d-flex flex-wrap"
        :class="{ 'justify-content-between': items.length >= 3 }"
      >
        <div
          style="width: 33%"
          v-for="item in items.items"
          :key="item.name"

        >
          <div v-show="item.got_by_user" class="menuItem_content" :style="{ 'background-image': `url(${item.icon_image})`}" @click="handleItemInfo({ name: 'itemInfo', item })"></div>
        </div>

      </div>
    </div>
    <div class="menuItem_backBtn" @click="handleItemInfo('functionMenu')"></div>
  </div>
</template>


<script>
export default {
  props: {
    items: {
      type: Object,
    },
    images: {
      type: Object,
    },
  },
  components: {
    // Item
  },
  methods: {
    // handleClick(val) {
    //   if (val === "none") {
    //     this.$emit("handleClick", val);
    //   } else {
    //     this.$emit("handleClick", val);
    //     console.log(`val`, val)
    //   }
    // },
    handleItemInfo(val) {
      this.$emit("handleItemInfo", val);
    },
  },
   created() {
    let vw = window.innerWidth;
    const setFillHeight = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    };

    window.addEventListener("resize", () => {
      if (vw === window.innerWidth) {
        return;
      }

      vw = window.innerWidth;
      setFillHeight();
    });
  },
};
</script>

<style lang="scss" scoped>
.bg {
  width: 100vw;
  height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
  background: rgba(0, 0, 0, 0.2);
  z-index: 15;
  position: absolute;
  top: 0;
  backdrop-filter: blur(5px);
  padding: 5.7vh 5.06vw 0 5.06vw;
}
.menuItem {
  // background: url("../../assets/background/bg_menuItemModal.png");
  background-repeat: no-repeat;
  width: 90vw;
  height: 82.1vh;
  height: calc(var(--vh, 1vh) * 82.1);
  background-size: cover;
  padding: 14.37% 1.78% 0 1.78%;
  position: relative;
  overflow: auto;
  &::-webkit-scrollbar {
    background: #e6e6e680 0% 0% no-repeat padding-box;
    box-shadow: inset -0.4vw 0.27vw 0px #00000029;
    width: 1.7vw;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #b3b3b3;
    box-shadow: inset -0.4vw 0.27vw 0px #00000029;
  }
}
.menuItem_backBtn {
  background: url("../../assets/icons/btn_back_menuItem.png");
  width: 18.4vw;
  height: 18.4vw;
  background-size: contain;
  position: absolute;
  top: 1.7vh;
  left: -2.94vw;
  cursor: pointer;
}

.menuItem_contents {
  // height: 66.4vh;
  overflow: auto;
  padding: 0 2.93vw;
  &::-webkit-scrollbar {
    background: #e6e6e680 0% 0% no-repeat padding-box;
    box-shadow: inset -0.4vw 0.27vw 0px #00000029;
    width: 1.7vw;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #b3b3b3;
    box-shadow: inset -0.4vw 0.27vw 0px #00000029;
  }
}

.menuItem_content {
  background: url("../../assets/icons/menuItem_icon.png");
  width: 24vw;
  height: 24vw;
  background-size: cover;
  margin-bottom: 2.93vw;
  //   &:nth-last-child(1){
  //      background: url('../../assets/icons/itemList_conversation_icon.png');

  //      background-size: cover;
  //      margin-bottom:3.73vw;
  //   }
}
</style>
