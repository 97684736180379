<template>
  <div class="bg" :style="{ 'background-image': `url(${globalImages.game_background_image})`}">
    <div class="bg-endPage" v-if="dialogueContent==='end'" >
      <FunctionMenu  @click.native="modalOpenNow ='functionMenu'" />
        <div class="endPage" :style="{ 'background-image': `url(${globalImages.dialog_image})`}">
            <div class="end_box_scroll" >
                <ul class="content">
                    <li v-for="(content,i) in globalContent" :key="i">
                        <p v-html="content"></p>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div v-else class="game">
      <FunctionMenu @click.native="modalOpenNow = 'functionMenu'" v-show="showFunctionMenu" />
      <OptionList
        v-show="dialogueContent==='option'"
        @optionChoose="handleOption"
        :images="globalImages"
        :options="options"
        :can_choose_again="can_choose_again"
      />
      <VideoInput v-if="videoLink" :link="videoLink" v-show="dialogueContent==='embed'" @toNextPage="handleNextPage" :images="globalImages"/>
      <Character v-if="mainCharacters" :character="mainCharacters" :bg_characters="background_characters" />
      <div class="game-conversation">
        <Dialogue
          :dialogueContent="rendenDialogueBox"
          :images="globalImages"
          v-if="globalImages"
          :contents="globalContent"
          @toNextPage="handleNextPage"
          @sendAnswer="handleOption"
          @handelFunctionMenuDisplay="handelFunctionMenuDisplay"
          :hints="hints"
        />
      </div>
    </div>
    <FunctionMenuModal
      v-if="functionMenu && modalOpenNow === 'functionMenu'"
      :menu="functionMenu"
      :images="globalImages"
      @handleClick="changeModal"
      @handleConversation="getConversation"
    />
    <MenuItemModal
      v-if="inventory && modalOpenNow === 'menuItem'"
      :items="inventory"
      :images="globalImages"
      @handleItemInfo="openItemInfo"
    />
    <ItemsInfo
      v-if="singleItem && modalOpenNow === 'itemInfo'"
      :item="singleItem"
      @handleClick="changeModal"
    />
    <ConversationList
      v-show="modalOpenNow === 'conversation'"
      :conversationLog="logArr"
       @handleClick="changeModal"
    />
  </div>
</template>

<script>
import FunctionMenu from "../../components/FunctionMenu/FunctionMenu.vue";
import FunctionMenuModal from "../../components/FunctionMenu/FunctionMenu_Modal.vue";
import MenuItemModal from "../../components/FunctionMenu/MenuItem_Modal.vue";
import ConversationList from "../../components/FunctionMenu/CoversationList.vue";
import ItemsInfo from "../../components/FunctionMenu/ItemsInfo.vue";
import Dialogue from "../../components/Dialogue_main.vue";
import OptionList from "../../components/OptionList.vue";
import Character from "../../components/Character.vue";
import VideoInput from "../../components/VideoInput.vue"
import { getGameData } from "@/apis/game.js";
import { getNextPage, sendOption } from "@/apis/nextPage.js";
import { getConversationList } from "@/apis/conversationList.js";
import {  getTokenInfo } from "@/apis/register.js";

export default {
  components: {
    FunctionMenu,
    Dialogue,
    OptionList,
    FunctionMenuModal,
    Character,
    MenuItemModal,
    ItemsInfo,
    ConversationList,
    VideoInput
  },
  data() {
    return {
      url: "https://test-api.phonocatstudio.com/api/testGame/A1",
      optionOpen: false,
      functionMenuOpen: false,
      modalOpenNow: "none",
      dialogueContent: "",
      data: null,
      mainCharacters: null,
      functionMenu: null,
      album: null,
      inventory: null,
      inventoryBg: null,
      singleItem: null,
      background_characters:null,
      globalImages: {
         hints_image: null,
        dialog_image: null,
        dialog_log_image: null,
        next_button_image: null,
        option_icon_image: null,
        close_button_image: null,
        hints_button_image: null,
        answer_button_image: null,
        game_background_image: null,
        dialog_background_image: null,
        groups_background_image: null,
        dialog_log_button_image:null,
        embed_background_image:null,
        question_background_image:null
      },
      globalContent: null,
      videoLink:null,
      options:null,
      hints:null,
      logArr:null,
      innerHeight:null,
      can_choose_again:null,
      showFunctionMenu:true
    };
  },
  created() {
    this.game_id = this.$route.params.game_id;
    document.title = this.game_id
    this.getDataInfo();
  },
  watch: {
    dialogueContent() {
      // console.log(this.dialogueContent);
    },
  },
  methods: {
    async handleOption(emitVal) {
      let OptionNextPageData = await sendOption(emitVal);
      localStorage.setItem(`${this.game_id}_scriptUrl`, OptionNextPageData.data.next);
      this.getDataInfo()
      // console.log(`nextPageData`, OptionNextPageData);
    },

    async getConversation(){
      let data = await getConversationList()
      this.modalOpenNow = "conversation";
      this.logArr = data
    },

    changeModal(emitVal) {
    if (emitVal.name === "物品欄" ||emitVal.name === "相簿") {
        this.inventory = emitVal;
        this.modalOpenNow = "menuItem";
        // console.log(` this.inventory`, this.inventory);
      } else {
        this.modalOpenNow = emitVal;
      }
    },
    openItemInfo(itemEmitVal) {
      if (itemEmitVal.name) {
        this.modalOpenNow = itemEmitVal.name;
        this.singleItem = itemEmitVal.item;
      } else {
        this.modalOpenNow = itemEmitVal;
      }
    },
    renderData(data) {
      this.dialogueContent = data.class;
      this.mainCharacters = data.main_characters;
      this.functionMenu = data.groups;
      this.background_characters= data.background_characters
      this.globalImages = {
        hints_image: data.hints_image,
        dialog_image: data.dialog_image,
        dialog_log_image: data.dialog_log_image,
        next_button_image: data.next_button_image,
        option_icon_image: data.option_icon_image,
        close_button_image: data.close_button_image,
        hints_button_image: data.hints_button_image,
        answer_button_image: data.answer_button_image,
        game_background_image: data.game_background_image,
        dialog_background_image: data.dialog_background_image,
        groups_background_image: data.groups_background_image,
        dialog_log_button_image:data.dialog_log_button_image,
        embed_background_image:data.embed_background_image,
        question_background_image:data.question_background_image
      };
      this.globalContent = data.contents;
      this.videoLink = data.link,
      this.options= data.options,
      this.can_choose_again= data.can_choose_again
      this.hints= data.hints
    },
    async getDataInfo() {
      let data = await getGameData();
        // console.log(`一開始取得的data資料:`, data);
        if(data.class){
          this.renderData(data);
        }else if( data.response && data.response.status === 500){
          let newStartPage = await getTokenInfo();
           localStorage.setItem(`${this.game_id}_scriptUrl`, newStartPage.data.next);
           this.getDataInfo() 
        }else if(data.next){
          localStorage.setItem(`${this.game_id}_scriptUrl`, data.next);
          this.getDataInfo() 
        }
        //else{
        //  this.$router.push(`/${this.game_id}/login`);
        //}
    },

    async handleNextPage() {
      let nextPageData = await getNextPage();
      localStorage.setItem(`${this.game_id}_scriptUrl`, nextPageData.data.next);
      this.getDataInfo()
      // console.log(`nextPageData`, nextPageData);
    },
    handelFunctionMenuDisplay(val){
      // console.log(`handelFunctionMenuDisplay`,val)
      this.showFunctionMenu = !val
    }
  },
  computed: {
    rendenDialogueBox() {
      return this.dialogueContent;
    },
  },
};
</script>
<style lang="scss" scoped>
.game {
  width: 100vw;
  height: 100vh;
 height: calc(var(--vh, 1vh) * 100);
  // background: url("../../assets/background/bg_game_long.jpg") no-repeat;
  background-size: cover;
  background-position: center;
}

 .bg{
     width: 100vw;
     height: 100vh;
       height: calc(var(--vh, 1vh) * 100);
    //  background: url("../../assets/background/bg_game_long.jpg") no-repeat;
     background-size: cover;
     background-position: center;
     background-repeat: no-repeat;
    }

    .endPage{
      height: 100vh;
      height: calc(var(--vh, 1vh) * 100);
      // background: url('../../assets/background/bg_playingRecord.png') no-repeat;
      background-size: cover;
      background-position:center;
      background-repeat: no-repeat;
      position: relative;
      padding: 14.37% 6.67% 0 6.67%;
    }
    .end_box_scroll {
        width: 80vw;
        height: 75vh;
        height: calc(var(--vh, 1vh) * 75);
        //padding: 14.37% 1.78% 0 1.78%;
        //position: relative;
        top: 55%;
        left: 47%;
        transform: translate(-50%, -50%);
        position: absolute;
        display: flex;
        justify-content: center;
        overflow: auto;
        &::-webkit-scrollbar {
          background: #e6e6e680 0% 0% no-repeat padding-box;
          box-shadow: inset -0.4vw 0.27vw 0px #00000029;
          width: 1.7vw;
        }
        &::-webkit-scrollbar-thumb {
          background-color: #b3b3b3;
          box-shadow: inset -0.4vw 0.27vw 0px #00000029;
        }
    }
    .content{
        width: 90%;
        list-style: none;
        p{
            margin-bottom: 0;
            //letter-spacing: 2.85px;
            letter-spacing: normal;
        }
        li{
            margin-bottom: 1rem;
        }
    }
</style>
