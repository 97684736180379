<template>
  <div class="bg">
    <div class="item">
      <div class="menuItem_backBtn" @click="handleClick('menuItem')"></div>
      <div class="menuItem_contents d-flex flex-wrap justify-content-between">
        <div class="item-img"><img :src="item.image" alt="" /></div>
      </div>
      <div class="item-introBox">
        <div class="intro_bg">
             <div class="intro_content">
             <p v-html="item.content"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

  props:{
    "item":{
      type:Object
    }
  },
  methods:{
      handleClick(val){
               this.$emit('handleClick', val); 
          }
  },
   created() {
    // let vw = window.innerWidth;
    // const setFillHeight = () => {
    //   const vh = window.innerHeight * 0.01;
    //   document.documentElement.style.setProperty("--vh", `${vh}px`);
    // };

    // window.addEventListener("resize", () => {
    //   if (vw === window.innerWidth) {
    //     return;
    //   }

    //   vw = window.innerWidth;
    //   setFillHeight();
    // });
  },
  mounted() {
    // // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    // let vh = window.innerHeight * 0.01
    // // Then we set the value in the --vh custom property to the root of the document
    // document.documentElement.style.setProperty('--vh', `${vh}px`)

    // // We listen to the resize event
    // window.addEventListener('resize', () => {
    //   // We execute the same script as before
    //   let vh = window.innerHeight * 0.01
    //   console.log(vh);
    //   document.documentElement.style.setProperty('--vh', `${vh}px`)
    // })
  },
};
</script>

<style lang="scss" scoped>
.bg {
  width: 100vw;
  height: 100vh;
 height: calc(var(--vh, 1vh) * 100);
  background: rgba(0, 0, 0, 0.2);
  z-index: 15;
  position: absolute;
  top: 0;
  backdrop-filter: blur(5px);
  padding: 5.7vh 5.06vw 0 5.06vw;
}

.item{
    width: 90vw;
    // height: 82.1vh;
    height: calc(var(--vh, 1vh) * 82.1);
    background-size: cover;
    padding: 23.75% 0 0 0;
    position: relative;
}

.menuItem_backBtn {
  background: url("../../assets/icons/btn_back_menuItem.png");
  width: 18.4vw;
  height: 18.4vw;
  background-size: contain;
  position: absolute;
  top: -4vh;
  left: -8vw;
  cursor: pointer;
}

.item-img{
  width: 88vw;
  // height: 58.67vw;
  img{
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.item-introBox{
   background: url("../../assets/background/bg_dialogue.png");
  width: 100vw;
  height: 41.23vh;
  height: calc(var(--vh, 1vh) * 41.23);
  // height: calc(var(--vh, 1vh) * 100);
  position: absolute;
  bottom: -9.9vh;
  right:-5.06vw;
  .intro_bg{
     background: url("../../assets/background/bg_dialogueBox_item.png") no-repeat
      center;
    width: 100%;
    height: 100%;
    background-size: contain;
    position: relative;
    // top: calc(var(--vh, 1vh) * 2);
  }
   .intro_content{
     width: 70%;
    letter-spacing: 0.38vw;
    font-size: 4vw;
    font-weight: bold; 
    height: 18vh;
    // height: calc(var(--vh, 1vh) * 100);
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;

   }
}
</style>
