<template>
  <div class="bg">
    <div v-if="conversationLog" class="conversationList">
      <div class="menuItem_backBtn" @click="handleClick('functionMenu')"></div>
      <div class="dialogue-box_scroll">
        <div v-for="(data, i) in conversationLog" :key="i" class="logBox">
          <div v-if="data.type === 'embed'">
            <iframe
              width="95%"
              height="100%"
              :src="data.link | ytUrltoEmbed"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <div v-else-if="data.type === 'chooseOption'">
            <span class="recordTextColor">{{ data.option_content }}</span>
          </div>
          <div v-else-if="data.type === 'answerQuestion'">
            <span class="answerTextColor">{{ data.answer }}</span>
          </div>
          <div v-else>
            <p v-show="data.name">{{ data.name }}</p>
            <p
              v-for="(c, idx) in data.contents"
              :key="idx"
              style="white-space: pre-wrap"
              v-html="c"
            >

            </p>
          </div>
        </div>

        <!-- <p>阿爾伯特．愛因斯坦：
      <br>
      『測不準原理現在可幫不了你了，史蒂芬。』
    </p>
      <p> 史蒂芬．霍金：
      <br>
      『你又錯了，阿爾伯特。』
      </p> 
      <p>
         艾薩克．牛頓：
        <br>
              『不用跟我解釋，物理是我發明的。』
        </p>
       <p><span class="recordTextColor">#選項１（選項紀錄的顏色#FFFF00）</span></p> 
       <p>
        阿爾伯特．愛因斯坦：
        <br>
        『測不準原理現在可幫不了你了，史蒂芬。』
       </p>
      <p><span class="answerTextColor">#問題的正確答案（答案紀錄的顏色#00FFFF）</span></p>
      <p>
         阿爾伯特．愛因斯坦：
        <br>
         『測不準原理現在可幫不了你了，史蒂芬。』
        </p>
      <p>
        史蒂芬．霍金：
        <br>
        『你又錯了，阿爾伯特。』
        </p>
      <p>
        艾薩克．牛頓：
        <br>
        『不用跟我解釋，物理是我發明的。』
        </p> -->
      </div>
    </div>
  </div>
</template>

<script>



export default {

props:{
  conversationLog:{
    type:Array
  }
},
  data() {
    return {
      // logArr: null,
    };
  },
  methods: {
    handleClick(val) {
      this.$emit("handleClick", val);
    },
    // async getConversation(){
    //   let data = await getConversationList()
    //   this.logArr = data
    //   console.log(data)
    // }
  },
  filters: {
    lineBreak(data) {
      return data.replace(/<br>/g, "\n");
    },
    ytUrltoEmbed(url) {
      let urlAry = url.split("watch?v=");
      let newUrl = `${urlAry[0]}embed/${urlAry[1]}`;
      return newUrl;
    },
  },
   created() {
    let vw = window.innerWidth;
    const setFillHeight = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    };

    window.addEventListener("resize", () => {
      if (vw === window.innerWidth) {
        return;
      }

      vw = window.innerWidth;
      setFillHeight();
    });
  },
};
</script>

<style lang="scss" scoped>
.bg {
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  background: rgba(0, 0, 0, 0.2);
  z-index: 15;
  position: absolute;
  top: 0;
  backdrop-filter: blur(5px);
  padding: 5.7vh 5.06vw 0 5.06vw;
}
.conversationList {
  background: url("../../assets/background/bg-conversationList.png") no-repeat;
  width: 90vw;
  height: 82.1vh;
  background-size: cover;
  padding: 14.37% 1.78%;
  position: relative;
}
.menuItem_backBtn {
  background: url("../../assets/icons/btn_back_menuItem.png");
  width: 18.4vw;
  height: 18.4vw;
  background-size: contain;
  position: absolute;
  top: -4vh;
  left: -8vw;
  cursor: pointer;
}

.conversationList {
  p {
    color: #fff;
    word-spacing: 2.85px;
    margin: 0;
  }
}

.dialogue-box_scroll {
  width: 80vw;
  height: 80%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  overflow: auto;
  &::-webkit-scrollbar {
    background: #e6e6e680 0% 0% no-repeat padding-box;
    box-shadow: inset -0.4vw 0.27vw 0px #00000029;
    width: 1.7vw;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #b3b3b3;
    box-shadow: inset -0.4vw 0.27vw 0px #00000029;
  }
}
.logBox {
  margin-bottom: 2rem;
}
.recordTextColor {
  color: #ffff00;
}

.answerTextColor {
  color: #00ffff;
}
</style>
