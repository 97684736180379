<template>
  <div v-if="images" class="optionList" >
    <div
      class="option"
      v-show="!op.been_choice || can_choose_again"
      v-for="(op, i) in options"
      :key="i"
      :style="{ 'background-image': `url(${images.option_icon_image})` }"
      @click="optionChoose(i)"
    >
      {{ op.content }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    images: {
      type: Object,
    },
    options: {
      type: Array,
    },
    can_choose_again:{
      type:Boolean
    }
  },
  data(){
    return{
      option:null
    }
  },
   created() {
  },
  methods: {
    optionClose(e) {
      if (e.target.className === "option") {
        return;
      }
      let open = false;
      this.$emit("close", open);
    },
    optionChoose(op){
      this.option = op
      this.$emit('optionChoose',{option:this.option})
    }
  },
};
</script>

<style lang="scss" scoped>
.optionList {
  padding: 0 7.47vw;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
   height: calc(var(--vh, 1vh) * 100);
   min-height: calc(var(--vh, 1vh) * 100);
  z-index: 20;
  padding-top: 11.5vh;
}

.option {
  padding: 1.7vh 2.4vw;
  //   border-radius: 2.67vw;
  //   border: 1.5px solid #000;
  background-size: contain;
  background-repeat: no-repeat;
  color: #000;
  font-size: 4vw;
  letter-spacing: 0.8vw;
  font-weight: 500;
  text-align: center;
  //   background: rgba(256, 256, 256, 0.9);
  margin: 1.5vh 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>