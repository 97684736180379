<template>
  <div class="characterPage">
    <div
      v-if="bg_characters && bg_characters.length > 0"
      class="character character-bg left"
      :style="{ 'background-image': `url(${bg_characters[0].image})` }"

    ></div>
    <div
      v-if="bg_characters && bg_characters.length > 1"
      class="character character-bg right"
      :style="{ 'background-image': `url(${bg_characters[1].image})` }"
    ></div>
    <div class="character main" v-for="c in character" :key="c.name">
      <!-- <img src="../assets/character/character_main.png" alt="" :style="{ 'background-image': `url(${images.option_icon_image})`}"/> -->
      <img :src="c.image" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    character: {
      type: Array,
    },
    bg_characters: {
      type: Array,
    },
  },
  created() {
    // let vw = window.innerWidth;
    // const setFillHeight = () => {
    //   const vh = window.innerHeight * 0.01;
    //   document.documentElement.style.setProperty("--vh", `${vh}px`);
    // };

    // window.addEventListener("resize", () => {
    //   if (vw === window.innerWidth) {
    //     return;
    //   }

    //   vw = window.innerWidth;
    //   setFillHeight();
    // });

  },
};
</script>

<style lang="scss" scoped>
.characterPage {
  overflow: hidden;
  display: flex;
  // justify-content: space-between;
}

.character {
  width: 80vw;
  height: 100vh;
   height: calc(var(--vh, 1vh) * 100);
  // margin: 0 auto;
  position: absolute;
  &.main {
    left: 15vw;
  }
  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
}

.character-bg {
  background-size: contain;
  display: inline-block;
  &.left {
    left: -25vw;
  }
  &.right {
    position: relative;
    // bottom:0;
    right: -55vw;
  }
}

@media screen and (min-width: 379px) {
  &.character-bg {
    &.left {
      // bottom:-16vw
    }
    &.right {
      position: relative;
      // bottom:-16vw;
    }
  }
}
</style>