<template>
  <div class="dialogue">
    <div class="dialogue-bg" :style="{ 'background-image': `url(${images.dialog_background_image})`}" >
      <div class="dialogue-box main" :style="{ 'background-image': `url(${images.dialog_image})`}" >
        <div class="dialogue-box_scroll" >
          <div   class="dialogue-box_content" v-show="dialogueContent === 'text'" @click ="addContent">
              <li style="white-space: pre-wrap" v-for="(content,i) in contents" :key="i">
                <p v-html="content"></p>
              <!-- {{content|lineBreak}} -->
              </li>
          </div>
          <div   class="dialogue-box_content" v-show="dialogueContent === 'option'">
              <p style="white-space: pre-wrap" v-for="(content,i) in contents" :key="i" >{{ content }}</p>
          </div>
          <div class="dialogue-box_content" v-show="dialogueContent === 'question'">
              <div class="answerInput">
                   <p style="white-space: pre-wrap" v-for="(content,i) in contents" :key="i" >{{ content }}</p>
                   <div class="bg_answer" :style="{ 'background-image': `url(${images.question_background_image})`}">
                    <div class="register-col ">
                     <input type="text" v-model="answer" />
                   </div>
                   <div class="answerInput_btns ">
                     <div class="answerInput_btn hint" :style="{ 'background-image': `url(${images.hints_button_image})`}" @click="handelHintsModal(true)"></div>
                     <div class="answerInput_btn comfirm" :style="{ 'background-image': `url(${images.answer_button_image})`}" @click="sendAnswer"></div>
                   </div>
                   </div>
                </div>
            <!-- <AnswerInput @handleHints="handleHintModal" :images="images" :contents="contents" /> -->
          </div>
        </div>
        <div class="d-flex justify-content-center" >
            <DialogueModal v-show="showHint" @closeHintsModal="handleHintModal" :images="images" :hints="hints"/>
        </div>
      </div>
       <div v-if="contents" class="next" :class="{'d-none':showContent<contents.length|| dialogueContent !== 'text' }" :style="{ 'background-image': `url(${images.next_button_image})`}"></div>
    </div>
  </div>
</template>

<script>
// import AnswerInput from "./AnswerInput.vue";
import DialogueModal from "./Dialogue_Modal.vue"

export default {
  components: {
    // AnswerInput,
    DialogueModal,
  },
  props:{
    images:{
      type:Object
    },
    contents:{
      type:Array
    },
     hints:{
      type:Array
    },
    dialogueContent:{
      type:String
    }
  },
  data() {
    return {
      showHint:false,
      hideNextPageBtn:true,
      showContent:0,
      showHint: false,
      answer:""
    };
  },
  watch:{
    
  },
  methods:{
    handleHintModal(val){
      this.showHint = val
      this.handelHintsModal(val)
    },
    // scroll(e){
    //   if(showContent<contents.length-1){
    //     this.hideNextPageBtn = true
        
    //   }else{
    //     this.hideNextPageBtn = false
    //   }
      
    // },
    addContent(){
      this.showContent+=1
      if(this.showContent>this.contents.length){
        this.$emit("toNextPage")
      }else if(this.showContent = this.contents.length){
        this.hideNextPageBtn = false
      }

      // }else{
      //   this.hideNextPageBtn = false
      //   this.$emit("toNextPage")
      // }
    },
    sendAnswer(){
      this.$emit('sendAnswer',{answer:this.answer})
      this.answer = "";
    },
    handelHintsModal(val){
      this.showHint = val
      this.$emit('handelFunctionMenuDisplay',val)
    }
  },
    filters: {
    lineBreak(data) {
      return data.replace(/<br>/g, "\n");
    }
  },
};
</script>
<style lang="scss" scoped>
.dialogue {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.dialogue-bg {
  // background: url("../assets/background/bg_dialogue.png");
  width: 100vw;
  height: 44%;
  position: absolute;
  bottom: 0;
//   overflow: hidden;
}
  .next{
    position: absolute;
    background:url('../assets/icons/next_icon.png');
    background-size: contain;
    width:  70%;
    height: 4.5vh;
    height: calc(var(--vh, 1vh) * 4.5);
    bottom:10vh;
    left:15vw; 
  }
.dialogue-box {
  &.main {
    // background: url("../assets/background/bg_dialogueBox_main.png") ;
    background-position:center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    background-size: contain;
    position: relative;
  }
  .dialogue-box_scroll {
    width: 80vw;
    height: 15vh;
    height: calc(var(--vh, 1vh) * 15);
    top: 57%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    display: flex;
    justify-content: center;
    overflow: auto;
    &::-webkit-scrollbar {
      background: #e6e6e680 0% 0% no-repeat padding-box;
      box-shadow: inset -0.4vw 0.27vw 0px #00000029;
      width: 1.7vw;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #b3b3b3;
      box-shadow: inset -0.4vw 0.27vw 0px #00000029;
    }
  }
  .dialogue-box_content {
    width: 90%;
    // height: 26vh;
   letter-spacing: calc(2.85px/2);
    font-size: 4vw;
    font-weight: bold;
    list-style: none;
  }
}

.register-col {
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #707070;
  input {
    background-color: transparent;
    border: none;
    outline: none;
    font-size: 4vw;
    letter-spacing: calc(2.85px / 2);
    width: 100%;
  }
  //   label {
  //   font-size: 4vw;

  //   color:#808080;
  //   font-weight: 500;
  //  }
}
.bg_answer {
  
  background-size: cover;
}

.answerInput_btns {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.answerInput_btn {
  width: 22.6%;
  height: 4.5vh;
  height: calc(var(--vh, 1vh) * 4.5);
  cursor: pointer;
  position: relative;
  z-index:5
}
.hint {
  // background: url("../assets/icons/btn_hint.png") ;
  background-repeat: no-repeat;
  background-size: contain;
}

.comfirm {
  // background: url("../assets/icons/btn_comfirm.png") ;
  background-repeat: no-repeat;
  background-size: contain;
}
</style>
