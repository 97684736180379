<template>
  <div class="functionMenu-btn">
   </div>
</template>
<script>
export default {
    data(){
      return{
      }
    },
    methods:{
    }
}
</script>
<style lang="scss" scoped>
    .functionMenu-btn{
      width: 20vw;
      height: 20vw;
      background: url("../../assets/icons/function_Menu_Icon.png") no-repeat;
      background-size: contain;
      cursor: pointer;
      position: absolute;
      top:1vh;
      left:-1.5vw;
      z-index:10
    }

 
</style>