<template>
  <div
    class="dialogue_modal"
    :class="showHint ? 'hintText' : 'hintList'"
    :style="renderBg"
  >
    <div class="hint-scroll">
      <div v-if="showHint">
        <div v-for="(hint, i) in hints" :key="i" class="hintText_content">
          <div v-show="i == hintIndex">
            <!-- <p>提示內容：</p> -->
            <p>{{ hint.content }}</p>
          </div>
        </div>
      </div>
      <div v-else>
        <div
          v-for="(hint, i) in hints"
          :key="i"
          @click="openHint(i)"
          class="hint"
        >
          {{ hint.title }}
        </div>
      </div>
    </div>
    <div class="hint_btns">
      <div class="hint_btn back" @click="handleBack()"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showHint: false,
      hintIndex: 0,
    };
  },
  props: {
    images: {
      type: Object,
    },
    hints: {
      type: Array,
    },
  },
  methods: {
    handleBack() {
      if (this.showHint) {
        this.showHint = false;
      } else {
        this.$emit("closeHintsModal", false);
      }
    },
    openHint(index) {
      this.showHint = true;
      this.hintIndex = index;
    },
  },
  computed: {
    renderBg() {
      if (!this.showHint) {
        return `url(${this.images.hints_image})`;
      }
      return;
    },
  },
};
</script>

<style lang="scss" scoped>
.dialogue_modal {
  height: 41.4vh;
  width: 85vw;
  bottom: 5.5vh;
  z-index: 10;
  padding: 6.7% 3% 6.7% 3%;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: .75rem;
}

.hintList {
  background: url("../assets/background/bg_hint.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.hintText {
  background: url("../assets/background/bg_hintText.png") no-repeat;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0 6% 0 6%;
}
.hintText_content {
  padding: 0 6.7%;
}

.hint {
  width: 88%;
  padding: 1.8vh 2.67vw;
  color: #000;
  font-size: 4vw;
  letter-spacing: 0.1vw;
  font-weight: 500;
  text-align: center;
  // background-size: contain;
  // background-repeat: no-repeat;
  background: rgba(256, 256, 256, 0.9);
  margin: 0 6% 1.5vh 6%;
   border-radius: 2.67vw;
  border: 1.5px solid #000;
}

.hint-scroll {
  height: 26vh;
  overflow: auto;
  width: 100%;
  &::-webkit-scrollbar {
    background: #e6e6e680 0% 0% no-repeat padding-box;
    box-shadow: inset -0.4vw 0.27vw 0px #00000029;
    width: 1.5vw;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #b3b3b3;
    box-shadow: inset -0.4vw 0.27vw 0px #00000029;
  }
}

.hint_btns {
  display: flex;
  align-items: flex-end;
  width: 100%;
  justify-content: space-between;
  height: 20%;
  padding: 0 6.7%;
  position: relative;
}

.hint_btn {
  width: 22.6%;
  height: 4.5vh;
  cursor: pointer;
}
.back {
  background: url("../assets/icons/btn_back.png") no-repeat;
  background-size: contain;
}
</style>